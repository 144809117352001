:root {
    /*--color-primary: #fdb600;*/
    --color-primary: #916AFF;
    --color-secondary: #000066;
    --color-accent: #9999cc;
    --color-accent-darker: #5b5bad;
    --color-input-border: #9e9e9e;
    --color-neutral-bg: #FAFAFA;
    --color-placeholders: #B6B6B6;
    --color-mid-grey: #6A6767;
    --color-lite-grey: #DEDEDE;
    --color-input-bg: #f6f6f9;
    --color-button-bg: #bebebe;
    --color-inactive-tab: #6A6767;
    --color-active-tab: #6A6767;
    --color-section-border: #979797;
    --color-light-backdrop: #e3e3e3;
    --color-error: #CA5486;
    --color-success: #30CAA0;

    /*--color-info: #51bcda;*/
    /*--color-good: #6bd098;*/
    /*--color-danger: #ef8157;*/
    /*--color-warning: #eea236;*/
    --color-background: #f5f6fa;
}
