#app {

  &.scroll-lock {
    max-height: 100vh;
    overflow: hidden;
  }
}

body {

  &.scroll-lock {
    overflow: hidden;
  }
}

svg {
  vertical-align: unset !important;
}

.sep {
  background-color: #BCBCBC;
  border-radius: 50%;
  display: inline-block;
  height: 4px;
  margin-left: 8px;
  margin-right: 8px;
  width: 4px;
  vertical-align: middle;
}
