@import '../base/color.scss';


.j-input {
  &:focus,
  &:active {
    outline: none;
  }
}

.j-search {
  position: relative;

  .j-search-icon {
    align-items: center;
    position: absolute;
    right: 0;
    top: 0;
  }
}

::placeholder {
  color: $mambaAlpha60;
}
