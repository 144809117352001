@import '../base/color.scss';


.j-checkbox {
  outline-color: $mamba;

  path {
    fill: $mamba;
  }

  &.j-checked {

    path {
      fill: $mamba;
    }
  }
}

input[type="checkbox"]:disabled ~ .j-checkbox {
  opacity: 40%;
}
