.j-fade-overlay {

  &.j-fade-hor {
    background-image: linear-gradient(90deg, rgba(255,255,255,1), rgba(255,255,255,0));

    &.j-fade-flipped {
      background-image: linear-gradient(270deg, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }

  &.j-fade-vert {
    background-image: linear-gradient(180deg, rgba(255,255,255,1), rgba(255,255,255,0));

    &.j-fade-flipped {
      background-image: linear-gradient(0deg, rgba(255,255,255,1), rgba(255,255,255,0));
    }
  }
}
