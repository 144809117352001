@mixin align-self-center() {
  align-self: center;
}

@mixin align-items-center() {
  align-items: center;
}

@mixin justify-space-between() {
  justify-content: space-between;
}

@mixin justify-center() {
  justify-content: center;
}

@mixin justify-start() {
  justify-content: flex-start;
}

@mixin flex-dir-col() {
  flex-direction: column;
}

@mixin flex-dir-row() {
  flex-direction: row;
}

@mixin flex() {
  display: flex;
}

@mixin flex-row() {
  @include flex();
  @include flex-dir-row();
}

@mixin flex-col() {
  @include flex();
  @include flex-dir-col();
}

@mixin flex-col-center() {
  @include flex-col();
  @include justify-center();
}

@mixin flex-col-start() {
  @include flex-col();
  @include justify-start();
}

@mixin flex-col-align-center() {
  @include flex-col-center();
  @include align-items-center();
}

@mixin flex-row-space-between() {
  @include flex-row();
  @include justify-space-between();
}

@mixin flex-row-start() {
  @include flex-row();
  @include justify-start();
}
