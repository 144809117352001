.j-logo-link {
  //noinspection CssUnknownTarget
  background-image: url('/images/logo.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.j-logo-link:hover .j-logo-link-text,
.j-logo-link:focus .j-logo-link-text,
.j-logo-link:active .j-logo-link-text,
.j-logo-link-text {
  color: transparent;
}
