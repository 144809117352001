@import '../base/color.scss';


button.j-switch {
  border-color: $ebonyLightest;
  min-width: 40px;

  & > div {
    border-color: $ebonyLightest;
  }

  &[aria-checked=checked], &[aria-checked=true] {
    background-color: $aqua;
    border-color: $aqua;
    color: $aqua;

    & > div {
      border-color: $aqua;
    }
  }
}
