html {
    box-sizing: border-box;
}

body {
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    width: 100vw;
    max-width: 100vw;
    font-size: 16px;
}

input, select { font-size: 100%; }

*, *:before, *:after {
    box-sizing: inherit;
    max-width: 100vw;
}

:global(.main-panel) {
    overflow: auto !important;
}

:global(.panelWrapper) {
    max-width: 100% !important;
}

:global(.table-responsive) {
    overflow: visible !important;
}

#modal {
    /*display: none;*/
}
