.j-trunc-multi {
  position: relative;
  overflow: hidden;
  padding-right: 1rem; /* space for ellipsis */
}

.j-trunc-multi::before {
  position: absolute;
  content: "...";
  right: 0;
  bottom: 0;
}

.j-trunc-multi::after {
  content: "";
  position: absolute;
  right: 0;
  width: 1rem;
  height: 1rem;
  //background: white;
}

.j-alert-heading {
  font-size: 24px !important;
}
