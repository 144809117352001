.ant-divider-horizontal {
  margin: 16px 0 !important;
}

.ant-pagination.mini {
  display: flex;
  padding-left: 8px;
  padding-right: 8px;
  justify-content: flex-end;

  .ant-pagination-item-active {
    border-color: rgba(0, 0, 0, 0.45);
  }
}

.ant-table-wrapper {

  .ant-pagination {
    padding-bottom: 16px;
    padding-right: 16px;
    padding-right: 16px;
  }
}

.ant-picker {
  box-shadow: 0 1px 1px rgba(0,0,0,0.008), 0 5px 4px rgba(0,0,0,0.008), 0 10px 8px rgba(0,0,0,0.008), 0 20px 16px rgba(0,0,0,0.008), 0 28px 18px rgba(0,0,0,0.008), 0 36px 24px rgba(0,0,0,0.008);
  padding-bottom: 6.5px !important;
  padding-top: 6.5px !important;
}
