.j-button {
  cursor: pointer;

  &.auth-button svg {
    vertical-align: middle !important;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &:not(:disabled) {
    transition: background-color 0.2s, box-shadow 0.2s;

    &:active,
    &:focus {
      outline: none !important;
    }
  }

  &:active {
    animation: anim-bop-down 400ms;
  }

  .j-icon-upload {
    vertical-align: middle !important;
  }
}
