@import "../mixins/flex.scss";


.swal {
  @include flex-col();

  & > div {
    border-color: rgba(41, 186, 165, 1) !important;

    & > div {
      background: transparent !important;
    }
  }

  div:first-child span:nth-child(1),
  div:first-child span:nth-child(2),
  div:first-child span:nth-child(3) {
    background-color: rgb(41, 186, 165) !important;
  }

  div:first-child div:nth-child(4) {
    border-color: rgba(41, 186, 165, 0.2) !important;
  }

  .btn-primary {
    margin-right: 0!important;
  }

  .btn-primary:not(.disabled):active:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 255, 123, 0.5) !important;
  }

  @media (max-width: 640px) {}
}
