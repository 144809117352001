@import '../mixins/flex.scss';


.j-header-title {
  @include align-self-center();

  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}
