.j-modal {
  display: none;
  top: 100px;

  &.j-modal--open {
    display: block;
  }
}

.j-modal,
.j-modal__overlay {
  position: fixed;
}

.j-modal,
.j-modal__overlay,
.j-modal__content {
  height: 100vh;
  left: 0;
  width: 100vw;
}

.j-modal {
  position: fixed;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  pointer-events: none;

  &.j-sidesheet {
    background-color: transparent;

    .j-modal__content {
      box-shadow: 0 0 22px rgba(0, 0, 0, 0.12), 0 0 12px rgba(0, 0, 0, 0.2);
      max-width: unset;
      width: 45vw;
      left: 55vw;
    }
  }
}

.j-modal__overlay {
  position: absolute;
  z-index: 1;
  //background: rgba(55, 58, 71, 0.9);
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
  -webkit-backface-visibility: hidden;

  &.j-modal__closer {
    cursor: pointer;
  }
}

.j-modal--open .j-modal__overlay {
  opacity: 1;
  pointer-events: auto;
}

.j-modal--open.j-sidesheet {
  .j-modal__overlay {
    opacity: 0.4;
  }
}

.j-modal__content {
  background: #fff;
  padding: 4em;
  text-align: center;
  position: relative;
  z-index: 5;
  opacity: 0;
}

.j-modal--open .j-modal__content {
  pointer-events: auto;
}

.j-modal.j-modal--open .j-modal__content,
.j-modal.j-modal--close .j-modal__content {
  -webkit-animation-duration: 0.3s;
  animation-duration: 0.3s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}

.j-modal.j-modal--open .j-modal__content {
  -webkit-animation-name: anim-open;
  animation-name: anim-open;
}

.j-modal.j-modal--close .j-modal__content {
  -webkit-animation-name: anim-close;
  animation-name: anim-close;
}

@-webkit-keyframes anim-open {
  0% { opacity: 0; -webkit-transform: scale3d(1.1, 1.1, 1); }
  100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); }
}

@keyframes anim-open {
  0% { opacity: 0; -webkit-transform: scale3d(1.1, 1.1, 1); transform: scale3d(1.1, 1.1, 1); }
  100% { opacity: 1; -webkit-transform: scale3d(1, 1, 1); transform: scale3d(1, 1, 1); }
}

@-webkit-keyframes anim-close {
  0% { opacity: 1; }
  100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); }
}

@keyframes anim-close {
  0% { opacity: 1; }
  100% { opacity: 0; -webkit-transform: scale3d(0.9, 0.9, 1); transform: scale3d(0.9, 0.9, 1); }
}

@media (min-width: 768px) {
  .j-modal__content {
    width: 50%;
    max-width: 560px;
    min-width: 290px;
  }
}
