@import '../mixins/flex.scss';


.j-mobile-menu {
  position: absolute;
  right: 100vw;

  &.j-open {
    animation: slideRight 0.2s;
    right: 0;
  }

  &.j-closing {
    animation: slideLeft 0.2s;
    right: 100vw;
  }

  .j-nav-links {
    top: 0;

    .j-mnenu-links-scroll {
      overflow: scroll;
    }

    .j-menu-fade-top,
    .j-menu-fade-btm {
      width: 100%;
      height: 40px;
    }
  }

  .j-mobile-menu-link {

    .j-icon {
      margin-bottom: 2px;
    }
  }

  .j-mobile-switcher {

    .j-switcher-title-icon {
      margin-top: 4px;
    }
  }

  .j-mobile-menu-link,
  .j-mobile-switcher-link {
    @include align-items-center();
    @include flex-row-start();

    width: 100%;
  }

  .j-menu-switcher-title {
    font-weight: 600;
  }

  .j-mobile-switcher-link {
    font-weight: 500;
  }
}

@keyframes slideRight {
  0% { right: 100vw; }
  100% { right: 0; }
}

@keyframes slideLeft {
  0% { right: 0; }
  100% { right: 100vw; }
}
