.j-picker-backdrop {
  display: none;
  z-index: 10000;

  &.open {
    display: block;
    animation: fadeBackdrop 0.2s;
  }
}

.j-picker-opts {

  &.j-bottom-sheet {
    transform: translateY(100%);
    transition: transform 0.2s;
    z-index: 10001;

    &.open {
      transform: translateY(0);
    }
  }

  &.j-picker-menu {
    display: none;
    z-index: 10000;

    &.open {
      display: block;
      animation: fadeBackdrop 0.2s;
    }
  }
}

@keyframes fadeBackdrop {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
