@import '../mixins/flex.scss';


.align-items-center {
  @include align-items-center();
}

.justify-space-between {
  @include justify-space-between();
}

.justify-center {
  @include justify-center();
}

.flex-dir-col {
  @include flex-dir-col();
}

.flex-dir-row {
  @include flex-dir-row();
}

.flex {
  @include flex();
}

.flex-col {
  @include flex-col();
}

.flex-row {
  @include flex-row();
}

.flex-row-space-between {
  @include flex-row-space-between();
}

.flex-col-start {
  @include flex-col-start();
}

.flex-col-center {
  @include flex-col-center();
}

.flex-col-align-center {
  @include flex-col-align-center();
}
