@import '../base/color.scss';


.j-select-wrapper {
  svg {
    margin-top: 6px;
    path {
      fill: $mamba;
      d: path("M6,9.6l1.3-1.3L12,13l4.7-4.6l0.7,0.7L18,9.8l-6.1,5.9L6,9.6z");
    }
  }
}
