$alto: #D5D5D5;
$aqua: #29BAA5;
$athensGrey: #E1E0E5;
$black: #000000;
$chatelle: #D3C6D7;
$ebony: #120E21;
$ebonyMed: rgb(67, 64, 79);
$ebonyLight: #4d4a59;
$ebonyLightest: #6f6c7b;
$heliotrope: #a98bfc;
$mamba: #99879D;
$mambaAlpha50: rgba(153, 135, 157, 0.5);
$mambaAlpha60: rgba(153, 135, 157, 0.6);
$purple: #916AFF;
$white: #FFFFFF;
