@import '../base/color.scss';


.j-button {

  .j-icon {
    //vertical-align: middle !important;
  }
}

.j-icon {
  vertical-align: unset !important;

  &.j-v-align-middle-force {
    vertical-align: middle !important;
  }

  &.j-icon-eye_off,
  &.j-bday-icon,
  &.profile-action-menu-icon {
    vertical-align: middle !important;
  }
}

.j-profile-icon {

  &.anticon-gift {

    path:last-child {
      fill: $heliotrope;
    }
  }
}

.j-picker-icon {
  vertical-align: unset !important;
}
