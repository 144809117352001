.height-100 {
  height: 100%;
}

.min-height-100 {
  min-height: 100%;
}

.width-100 {
  width: 100%;
}

.min-width-100 {
  min-width: 100%;
}

.full-screen {
  height: 100vh;
  width: 100vw;
}
