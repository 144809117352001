:global(.withDeferredRenderFade) {

}

:global(.withDeferredRenderFade-enter) {
    opacity: 0.01 !important;
}

:global(.withDeferredRenderFade-enter-active) {
    opacity: 1 !important;
    transition: opacity 180ms ease-out;
}

:global(.withDeferredRenderFade-exit) {
    opacity: 1 !important;
}

:global(.withDeferredRenderFade-exit-active) {
    opacity: 0.01 !important;
    transition: opacity 180ms ease-out;
}
