@import '../mixins/flex.scss';


.j-button {

  &.j-icon-button {
    @include flex-col-center();

    .j-icon {
      //vertical-align: middle !important;
    }
  }
}
