.j-alphabet-list {

  .j-box {

    &:last-child {
      max-height: 22px !important;

      .j-text {
        max-height: 22px !important;
        padding-bottom: 4px;
      }
    }
  }
}
