.z-500 {
  z-index: 500;
}

.z-600 {
  z-index: 600;
}

.z-700 {
  z-index: 700;
}

.z-800 {
  z-index: 800;
}

.z-900 {
  z-index: 900;
}

.z-1000 {
  z-index: 1000;
}
